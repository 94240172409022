<template>
    <div>
        <br>
        <h3 class="text-dark">Rules</h3>
        <br>
        <my-box>
            <div class="d-flex">
                <b-button size="sm" variant="primary" squared class="mr-2">Create</b-button>
            </div>
        </my-box>
        <br>
        <div v-if="$store.state.system.rule.response != null">
            <my-box>
                <my-table borderless striped :items="$store.state.system.rule.response.route" :fields="table.fields" @search="loadRules" show-btn-ref></my-table>
            </my-box>
        </div>
        <div v-else>
            <my-loader title="Failed to load" :error-msg="errorMsg" :has-loaded="hasLoaded">
                <b-link variant="link" @click="() => {hasLoaded=false;loadRoles();}">Try Again</b-link>
            </my-loader>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            hasLoaded: false,
            errorMsg: null,
            table: {
                fields: [
                    {
                        key: 'name',
                        sortable: true,
                        filter: 'like'
                    },
                    {
                        key: 'module_id',
                        sortable: true,
                        label: 'Module',
                        filter: 'like'
                    },
                    {
                        key: 'value',
                        sortable: true,
                        filter: 'like'
                    },
                    {
                        key: 'activities',
                        sortable: false,
                        label: '',
                    },
                ]
            },
        };
    },
    methods: {
        loadRules(filter) {
            this.$store.dispatch("system/rule/get", {params: filter}).then((response) => {
                this.hasLoaded = true
            }).catch(error => {
                this.hasLoaded = true
                this.errorMsg = error.status + ' - ' + error.statusText
            })
        }
    },
    created() {
        this.loadRules()
    },
};
</script>